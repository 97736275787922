@font-face {
 font-family: 'francis-bold';
 src: url('./fonts/francis-bold.eot');
 src: url('./fonts/francis-bold.eot?#iefix') format('embedded-opentype'),
      url('./fonts/francis-bold.woff') format('woff'),
      url('./fonts/francis-bold.ttf') format('truetype'),
      url('./fonts/francis-bold.svg#francis-bold') format('svg');
 font-weight: normal;
 font-style: normal;
}
@font-face {
 font-family: 'francis-bold-italic';
 src: url('./fonts/francis-bold-italic.eot');
 src: url('./fonts/francis-bold-italic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/francis-bold-italic.woff') format('woff'),
      url('./fonts/francis-bold-italic.ttf') format('truetype'),
      url('./fonts/francis-bold-italic.svg#francis-bold') format('svg');
 font-weight: normal;
 font-style: normal;
}
@font-face {
 font-family: 'francis-light';
 src: url('./fonts/francis-light.eot');
 src: url('./fonts/francis-light.eot?#iefix') format('embedded-opentype'),
      url('./fonts/francis-light.woff') format('woff'),
      url('./fonts/francis-light.ttf') format('truetype'),
      url('./fonts/francis-light.svg#francis-bold') format('svg');
 font-weight: normal;
 font-style: normal;
}
@font-face {
 font-family: 'francis-light-italic';
 src: url('./fonts/francis-light-italic.eot');
 src: url('./fonts/francis-light-italic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/francis-light-italic.woff') format('woff'),
      url('./fonts/francis-light-italic.ttf') format('truetype'),
      url('./fonts/francis-light-italic.svg#francis-bold') format('svg');
 font-weight: normal;
 font-style: normal;
}
@font-face {
 font-family: 'francis-medium';
 src: url('./fonts/francis-medium.eot');
 src: url('./fonts/francis-medium.eot?#iefix') format('embedded-opentype'),
      url('./fonts/francis-medium.woff') format('woff'),
      url('./fonts/francis-medium.ttf') format('truetype'),
      url('./fonts/francis-medium.svg#francis-bold') format('svg');
 font-weight: normal;
 font-style: normal;
}
@font-face {
 font-family: 'francis-medium-italic';
 src: url('./fonts/francis-medium-italic.eot');
 src: url('./fonts/francis-medium-italic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/francis-medium-italic.woff') format('woff'),
      url('./fonts/francis-medium-italic.ttf') format('truetype'),
      url('./fonts/francis-medium-italic.svg#francis-bold') format('svg');
 font-weight: normal;
 font-style: normal;
}
@font-face {
 font-family: 'francis-regular';
 src: url('./fonts/francis-regular.eot');
 src: url('./fonts/francis-regular.eot?#iefix') format('embedded-opentype'),
      url('./fonts/francis-regular.woff') format('woff'),
      url('./fonts/francis-regular.ttf') format('truetype'),
      url('./fonts/francis-regular.svg#francis-bold') format('svg');
 font-weight: normal;
 font-style: normal;
}
@font-face {
 font-family: 'francis-regular-italic';
 src: url('./fonts/francis-regular-italic.eot');
 src: url('./fonts/francis-regular-italic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/francis-regular-italic.woff') format('woff'),
      url('./fonts/francis-regular-italic.ttf') format('truetype'),
      url('./fonts/francis-regular-italic.svg#francis-bold') format('svg');
 font-weight: normal;
 font-style: normal;
}
