.scaler {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  overflow:hidden;
  pointer-events: none;
  .frame {
    position:absolute;
    width:var(--width);
    height:var(--height);
    left:var(--left);
    top:var(--top);
    transform-origin: top left;
    transform: scale(var(--ratio));
  }
}
