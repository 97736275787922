.messages {
  position:fixed;
  z-index:999;
  width:var(--width);
  height:var(--reverseWindowHeight);
  left:var(--left);
  top:0;
  transform-origin: top left;
  transform: scale(var(--ratio));
  pointer-events: none;
  .message {
    position:absolute;
    width:calc( 100% - 60px);
    bottom:30px;
    left:30px;
    color:#FFF;
    background-color: #000e;
    padding:15px;
    box-sizing: border-box;
    border-radius: 8px;
    opacity:0;
    transition:opacity 1s;
    pointer-events: none;
    display: flex;
    align-items: center;
    text-align:center;
    &.active {
      pointer-events: all;
      opacity:1;
    }
    .text {
      font-weight:300;
      font-size:1.2em;
    }
    &.avertissement {
      border-radius: 8px;
      border: 1px solid #FFF;
      .text {
        flex-grow:1;
        max-width:calc(100% - 55px);
      }
    }
    .warning {
      width:40px;
      height:40px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: var(--shared-assets-warning-svg,url('../assets/warning.svg'));
      margin-right:15px;
    }
  }
}
