.landing {
  user-select: none;
  background-color:#000;
  background-image:var(--background);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height:100%;
  & .landing-mask {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:radial-gradient(#000c, #000f);;
  }
  & .logo {
    position:absolute;
    top:var(--top);
    left:var(--left);
    height:var(--height);
    width:var(--width);
    transform:scale(var(--ratio));
    transform-origin: left top;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    & img {
      width:70%;
      margin-bottom:50px;
      object-fit: contain;
    }
  }
  & .landing-messages {
    width:100%;
  }
  & .landing-message {
    padding:0 80px;
  }
  & .landing-message-content {
    margin:0 30px;
    display: flex;
    & .landing-message-bulle {
      padding:10px;
      font-size:22px;
      margin: 5px 0;
      position:relative;
      min-width: 10px;
      min-height:10px;
      & .lien {
        cursor:pointer;
        text-decoration: underline;
        text-decoration-thickness: var(--pixelSize);
        font-weight: bold;
      }
      & .text {
        position:relative;
        z-index:1;
        white-space: pre-line;
      }
    }
  }
  & .landing-message-right {
    text-align: right;
    & .landing-message-content {
      justify-content: flex-end;
      color:#fff;
    }
  }
  & .landing-message-left {
    text-align: left;
    & .landing-message-content {
      justify-content: flex-start;
      color:#000;
    }
  }
}
