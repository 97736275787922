.progress-container {
  position:relative;
  width:100%;
  padding-bottom:100%;
  svg {
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    transform-origin: center;
    transform:rotate(270deg);
    circle {
      stroke-dasharray:0,301;
      opacity:1;
      transition:opacity 0.5s, stroke-dasharray 1s;
    }
  }
  &.reset {
    circle {
      transition: none;
      opacity:0;
    }
  }
}
