.themes {
  user-select: none;
  background-color:#000;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height:100%;
  overflow:hidden;
  .themes-mask {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:radial-gradient(#000c, #000f);;
  }
  .themes-inner {
    position:absolute;
    top:var(--top);
    left:var(--left);
    height:var(--height);
    width:var(--width);
    transform-origin: left top;
    transform: scale(var(--ratio));
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .themes-chooser {
      position:relative;
      width:400px;
      max-width:90%;
      height:255px;
      .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom:0;
        .swiper-pagination-bullet {
          margin:0 8px;
          background-color: #FFF;
          opacity:0.3;
          &.swiper-pagination-bullet-active {
            opacity:1;
          }
        }
      }
      .swiper-slide {
        height:255px;
        color:#FFF;
        font-family:'francis-regular';
        font-size:120px;
        line-height:0.85;
        white-space: pre-line;
        text-align: center;
        .theme-inner {
          position:absolute;
          top:0;
          left:75px;
          width:calc(100% - 150px);
          bottom:0;
        }
      }
      .swiper-button-prev {
        background-image:var(--shared-assets-arrow-left-svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width:50px;
        height:50px;
        opacity:0.8;
        transition: opacity 0.2s;
        &:hover {
          opacity:1;
        }
      }
      .swiper-button-prev::after {
        content:'';
      }
      .swiper-button-next {
        background-image:var(--shared-assets-arrow-right-svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width:50px;
        height:50px;
        opacity:0.8;
        transition: opacity 0.2s;
        &:hover {
          opacity:1;
        }
      }
      .swiper-button-next::after {
        content:'';
      }
      .swiper-button-prev.swiper-button-disabled, & .swiper-button-next.swiper-button-disabled {
        opacity:0.2;
      }
    }
    .portraits-chooser {
      a {
        color:#FFF;
        text-decoration: none;
      }
      position:relative;
      width:133.33%;
      &.shift-left {
        left:-16.67%;
      }
      height:260px;
      .swiper {
        overflow: visible;
      }
      .swiper-slide {
        height:260px;
        color:#FFF;
        font-family:'francis-regular';
        font-size:110px;
        line-height:0.85;
        white-space: pre-line;
        text-align: center;
      }
      .swiper-button-prev {
        background-image:var(--shared-assets-arrow-left-svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        opacity:0.5;
        transition: opacity 0.2s;
        top: calc(100% - var(--swiper-navigation-size) + 25px);
        &:hover {
          opacity:1;
        }
      }
      .swiper-button-prev::after {
        content:'';
      }
      .swiper-button-next {
        background-image:var(--shared-assets-arrow-right-svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        opacity:0.5;
        transition: opacity 0.2s;
        top: calc(100% - var(--swiper-navigation-size) + 25px);
        &:hover {
          opacity:1;
        }
      }
      .swiper-button-next::after {
        content:'';
      }
      .portrait-slide {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height:260px;
        cursor:pointer;
        .portrait-medaillon {
          position:relative;
          margin-top:20px;
          height:158px;
          width:158px;
          .portrait-photo {
            position:absolute;
            top:4px;
            left:4px;
            height:150px;
            width:150px;
            border-radius:150px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            box-shadow: 0 0 20px #000;
          }
          .portrait-progress {
            position:absolute;
            top:0;
            left:0;
            height:100%;
            width:100%;
          }
        }
        .portrait-nom {
          font-size:50px;
        }
      }
    }
  }
}
