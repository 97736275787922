.main-menu {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:#000;
  opacity:0;
  transition: opacity 0.5s;
  pointer-events: none;
  z-index:1001;
  .menu-inner.show {
    .menu-item {
      pointer-events: none;
    }
  }

  &.show {
    pointer-events: all;
    opacity:1;
    .menu-inner.show {
      .menu-item {
        pointer-events: all;
      }
    }
  }
  .main-menu-inner {
    position:absolute;
    width:var(--width);
    height:var(--reverseWindowHeight);
    left:var(--left);
    top:0;
    transform-origin: top left;
    transform: scale(var(--ratio));
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &>div {
      position:relative;
      display:flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .main-menu-item {
      border-top:1px solid #FFF;
      &:last-of-type { border-bottom:1px solid #FFF; }
      color:#FFF;
      font-family:'francis-regular';
      font-size:55px;
      line-height:1;
      padding-bottom:0.3em;
      height:1.3em;
      box-sizing: border-box;
      white-space: pre-line;
      max-width:65%;
      width:500px;
      text-align: center;
      opacity: 1;
      transition: background-color 0.5s, height 0.5s, opacity 0.5s, padding-bottom 0.5s;
      cursor:pointer;
      &.menu-spacer {
        height:100px;
        cursor:auto;
      }
      &:hover {
        background-color: #FFF2;
      }
      &.menu-spacer:hover {
        background-color: #000;
      }
      &.hide {
        height: 0;
        opacity: 0;
        padding-bottom: 0;
      }
      &.active {
        border-top:1px solid #FFF0;
        border-bottom:1px solid #FFF0;
        &:hover {
          background-color: #FFF0;
        }
      }
    }
    .menu-page {
      position:relative;
      width:100%;
      color:#FFF;
      height: 0;
      opacity:0;
      transition: height 0.5s, opacity 0.5s linear 0.5s;
      &.active {
        opacity:1;
        height: calc(100% - 1.3em);
      }
      .page-inner {
        position:absolute;
        top:0;
        left:calc(0 - var(--left));
        width:var(--reverseWindowWidth);
        height:100%;
        box-sizing:border-box;
        img[alt='icon'] {
          width:50px;
          margin:5px;
          vertical-align: middle;
        }
        .scrollbars {
          position:absolute;
          top:0;
          left:0;
          width:100%;
          height:100%;
          .scrollbars-inner {
            position:absolute;
            box-sizing: border-box;
            top:0;
            padding:50px;
            left:calc( ( var(--reverseWindowWidth) - var(--width) ) / 2 );
            width:var(--width);
          }
        }
        .top-gradient {
          display: block;
          position:absolute;
          top:0;
          left:0;
          width:100%;
          height:50px;
          background: linear-gradient(#000F,#0000);
        }
        .bottom-gradient {
          display: block;
          position:absolute;
          bottom:0;
          left:0;
          width:100%;
          height:50px;
          background: linear-gradient(#0000,#000F);
        }
        &.chronologie {
          position:absolute;
          top:0;
          left:calc(0 - var(--left));
          width:var(--reverseWindowWidth);
          height:100%;
          background-color:#000;
          img, object {
            width:2000px;
            height:2000px;
            object-fit: contain;
            object-position:center;
            pointer-events: none;
          }
        }
        &.historique {
          .reset {
            position:relative;
            width:50px;
            height:50px;
            opacity:0.8;
            transition:opacity 0.2s;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            display:flex;
            justify-content: center;
            align-items: center;
            color:#FFF;
            cursor:pointer;
            background-image: var(--shared-assets-reset-svg,url('../../shared/assets/reset.svg'));
          }
          .reset-ok {
            position:relative;
            width:50px;
            height:50px;
            opacity:0.8;
            transition:opacity 0.2s;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            display:flex;
            justify-content: center;
            align-items: center;
            color:#FFF;
            cursor:pointer;
            background-image: var(--shared-assets-check-svg,url('../../shared/assets/check.svg'));
          }
        }
        &.glossaire {
          h3 {
            cursor:pointer;
            font-weight:400;
            font-size:1.5em;
          }
        }
      }
    }
  }
}
