.lettres {
  user-select: none;
  background-color:#000;
  height:100%;
  overflow:hidden;
  a {
    color:inherit;
    text-decoration: none;
  }
  .lettres-inner {
    position:absolute;
    top:0;
    left:0;
    height:var(--height);
    width:var(--width);
    transform-origin: left top;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .lettres-chooser {
      position:relative;
      width:250px;
      height:600px;
      .lettres-lettre-inner {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .lettres-lettre-vignette {
          height:350px;
          width:220px;
          background-position: center bottom;
          background-size: contain;
          background-repeat: no-repeat;
        }
        .lettres-lettre-date {
          font-size:35px;
          color:#2ca198;
          font-family:'francis-medium';
          margin-top:10px;
          margin-bottom:15px;
        }
        .lettres-lettre-arrow {
          background-image: var(--app-assets-arrow-down-white-svg);
          width:20px;
          height:20px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          margin:10px 0;
        }
        .lettres-lettre-from,.lettres-lettre-to  {
          font-size:20px;
          line-height:1.2;
          font-family:'Roboto';
          padding:0 20px;
        }
      }
      .swiper {
        overflow:unset;
        height:100%;
      }
      .swiper-slide {
        color:#FFF;
        font-family:'francis-regular';
        font-size:120px;
        line-height:0.85;
        white-space: pre-line;
        text-align: center;
        cursor:pointer;
      }
      .swiper-button-prev {
        background-image:var(--shared-assets-arrow-left-svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        opacity:0.5;
        transition: opacity 0.2s;
        transform: translate3d(-23px,0,0);
        top:calc(100% - 30px);
        &:hover {
          opacity:1;
        }
        left:-10px;
        width:50px;
        height:50px;
      }
      .swiper-button-prev::after {
        content:'';
      }
      .swiper-button-next {
        background-image:var(--shared-assets-arrow-right-svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        opacity:0.5;
        transition: opacity 0.2s;
        transform: translate3d(23px,0,0);
        top:calc(100% - 30px);
        &:hover {
          opacity:1;
        }
        right:-10px;
        width:50px;
        height:50px;
      }
      .swiper-button-next::after {
        content:'';
      }
      .swiper-button-prev.swiper-button-disabled, & .swiper-button-next.swiper-button-disabled {
        opacity:0.2;
        pointer-events: all;
      }
    }
  }
}
