.orientation-mask {
  position:fixed;
  z-index:1002;
  width:100%;
  height:100%;
  left:0;
  top:0;
  background-color: #000;
  pointer-events: all;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: var(--app-assets-rotation-device-svg,url('../assets/rotation-device.svg'));
  display: none;
  &.on {
    display:block;
  }
}
