.tapuscrit-content {
  .lettre-surtitre {
    text-align: center;
  }
  .lettre-interlocuteurs {
    text-align: center;
    margin:5px 0;
    strong {
      font-size: 1.2em;
    }
  }
  color:#fff;
  h3 {
    font-size:30px;
    line-height: 1;
    margin:15px 0;
  }
  height:100%;
  overflow-y: auto;
  .tapuscrit-content-top, .tapuscrit-content-bottom {
    height:15px;
  }
  .tapuscrit-text {
    color:#000;
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    background-color: #FFF;
    border-radius: 16px;
    overflow-y:auto;
    height:calc(100% - 80px);
    .tapuscrit-text-inner {
      padding:15px;
    }
    .top-gradient {
      display: block;
      position:absolute;
      border-radius: 16px 16px 0 0;
      top:0;
      left:0;
      width:100%;
      height:30px;
      background: linear-gradient(#FFFF,#FFF0);
    }
    .bottom-gradient {
      display: block;
      position:absolute;
      bottom:0;
      left:0;
      width:100%;
      height:30px;
      background: linear-gradient(#FFF0,#FFFF);
    }
  }
}
