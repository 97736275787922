.menu-container-top, .menu-container-bottom, .menu-container-progress {
  position:absolute;
  width:100%;
  opacity:1;
  transition:opacity 0.5s;
  .menu-inner {
    pointer-events: none;
    position:absolute;
    height:65px;
    width:var(--width);
    left:var(--left);
    &.full-width {
      width:var(--reverseWindowWidth);
      left:0;
    }
    transform:scale(var(--ratio));
    display:flex;
    justify-content: space-evenly;
    align-items: center;
    padding:0 15px;
    box-sizing:border-box;
    transition:opacity 0.5s;
    opacity:0;
    &.show {
      opacity:1;
      .menu-item {
        pointer-events: all;
      }
    }
    .menu-item {
      position:relative;
      width:50px;
      height:50px;
      opacity:0.8;
      transition:opacity 0.2s;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      display:flex;
      justify-content: center;
      align-items: center;
      color:#FFF;
      cursor:pointer;
      &.menu-home {
        background-image: var(--shared-assets-home-svg,url('../../assets/home.svg'));
      }
      &.menu-audio-on {
        background-image: var(--shared-assets-audio-on-svg,url('../../assets/audio-on.svg'));
      }
      &.menu-audio-off {
        background-image: var(--shared-assets-audio-off-svg,url('../../assets/audio-off.svg'));
      }
      &.menu-settings {
        background-image: var(--shared-assets-cog-svg,url('../../assets/cog.svg'));
      }
      &.menu-tapuscrit {
        background-image: var(--shared-assets-tapuscrit-svg,url('../../assets/tapuscrit.svg'));
      }
      &.menu-portrait {
        background-image: var(--shared-assets-bulles-svg,url('../../assets/bulles.svg'));
      }
      &.menu-lettres {
        background-image: var(--shared-assets-lettre-svg,url('../../assets/lettre.svg'));
      }
      &.menu-docs {
        background-image: var(--shared-assets-docs-svg,url('../../assets/docs.svg'));
      }
      &.menu-plus {
        background-image: var(--shared-assets-more-svg,url('../../assets/more.svg'));
      }
      &.menu-voix {
        background-image: var(--shared-assets-voix-svg,url('../../assets/voix.svg'));
      }
      &.menu-pause {
        background-image: var(--shared-assets-pause-svg,url('../../assets/pause.svg'));
      }
      &.menu-play {
        background-image: var(--shared-assets-play-svg,url('../../assets/play.svg'));
      }
      &.menu-stop {
        background-image: var(--shared-assets-stop-svg,url('../../assets/stop.svg'));
      }
      &.menu-menu {
        background-image: var(--shared-assets-burger-svg,url('../../assets/burger.svg'));
      }
      &.menu-close {
        background-image: var(--shared-assets-close-svg,url('../../assets/close.svg'));
      }
    }
    .menu-item:hover {
      opacity:1;
    }
    .combo {
      position:relative;
      .menu-item-secondary {
        position:absolute;
        width:30px;
        height:30px;
        bottom:98px;
        left:331px;
      }
    }
  }
  &.inline {
    position:relative;
    height:65px;
    .menu-inner {
      width:100%;
      left:0;
      transform:scale(1);
    }
  }
}
.menu-container-top {
  top:0px;
  width:100%;
  left:0;
  .menu-inner {
    padding-top:15px;
    transform-origin: top left;
  }
  &.inline {
    position:relative;
    height:65px;
    .menu-inner {
      width:100%;
    }
  }
}
.menu-container-bottom {
  top:calc( 100% - 65px ) ;
  .menu-inner {
    padding-bottom:30px;
    transform-origin: bottom left;
  }
  &.inline {
    position:relative;
    top:0;
    .menu-inner {
      width:100%;
    }
  }
}
.menu-container-progress {
  bottom:0 ;
  pointer-events: none;
  .menu-inner {
    position:absolute;
    bottom:0;
    height:112px;
    transform-origin: bottom left;
    .menu-progress-container {
      position:absolute;
      width:73%;
      height:32px;
      top:0;
      left:13.5%;
      overflow:hidden;
      .menu-progress-wrapper {
        position:absolute;
        width:calc(100% - 12px);
        height:100%;
        left:6px;
        top:0;
        .background {
          position:absolute;
          width:100%;
          height:2px;
          bottom:16px;
          left:0;
          border-radius:2px;
          background-color: #FFF8;
        }
        .menu-progress-inner {
          position:absolute;
          width:0;
          height:2px;
          bottom:16px;
          left:0;
          border-radius:2px;
          background-color: #FFF;
          .target {
            position:absolute;
            width:calc( 2 * var(--width));
            height:32px;
            bottom:-16px;
            right:calc( 0px - var(--width));
            pointer-events: all;
            cursor:pointer;
            .target-inner{
              position:absolute;
              width:12px;
              height:12px;
              bottom:11px;
              border-radius:6px;
              right:calc(50% - 6px);
              background-color: #FFF;
            }
          }
        }
      }
    }
  }
  &.inline {
    position:relative;
    top:0;
    .menu-inner {
      width:100%;
    }
  }
}
