body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, body, #root {
  height:100%;
  min-height: var(--screenHeight, 100%);
  user-select: none;
  -webkit-touch-callout: none;                /* prevent callout to copy image, etc when tap to hold */
  -webkit-user-select: none;                  /* prevent copy paste, to allow, change 'none' to 'text' */
  overflow:hidden;
  -webkit-tap-highlight-color: transparent;
  background-color: #000;
}
.debug {
  position:fixed;
  top:0;
  left:0;
  color:#FFF;
  z-index:10000;
}
