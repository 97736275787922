iframe.audio-context {
  border:none;
  position:fixed;
  top:0;
  left:0;
  width:0;
  height:0;
  z-index:0;
  &.click-mask {
    width:100%;
    height:100%;
    z-index:1000000;
  }
}
