.player-container {
  position:relative;
  height:100%;
  .next-btn-container {
    position:absolute;
    bottom:15px;
    width:100%;
    text-align: center;
    color:#FFF;
    a {
      color:#FFF;
      text-decoration: none;
    }
  }
}
