.lettre-inner {
  width:100%;
  height:100%;
  background-color: #000;
  user-select: none;
  cursor:grab;
  &>div, &>div>div {
    width:100%;
    height:100%;
  }
  & .lettre-mask {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    pointer-events: none;
    background: radial-gradient(#0000, #000C);
  }
  .lettre-viewport {
    position:absolute;
    top:calc(50% - 1000px);
    left:calc(50% - 1000px);
    width:2000px;
    height:2000px;
    .lettre-viewport-inner {
      position:absolute;
      top:0;
      left:0;
      width:2000px;
      height:2000px;
      img {
        width:100%;
        height:100%;
        object-fit: contain;
      }
    }
  }
}
