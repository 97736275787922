.bulle {
  padding:10px;
  font-size:22px;
  margin: 5px 0;
  position:relative;
  min-width: 10px;
  min-height:10px;
  .lien {
    cursor:pointer;
    text-decoration: underline;
    text-decoration-thickness: var(--pixelSize);
    font-weight: bold;
  }
  .text {
    position:relative;
    z-index:1;
    white-space: pre-line;
  }
  &.bulle-right {
    .background-0 {
      position:absolute;
      z-index:0;
      top:0;
      left:0;
      width:50%;
      height:50%;
      background-image: var(--shared-assets-bulle-right-svg,url('../../assets/bulle-right.svg'));
      background-position: left top;
      background-repeat: no-repeat;
    }
    .background-1 {
      top:0;
      right:-16px;
      position:absolute;
      z-index:0;
      width:calc(50% + 18px);
      height:50%;
      background-image: var(--shared-assets-bulle-right-svg,url('../../assets/bulle-right.svg'));
      background-position: right top;
      background-repeat: no-repeat;
    }
    .background-2 {
      bottom:0;
      left:0;
      position:absolute;
      z-index:0;
      width:50%;
      height:51%;
      background-image: var(--shared-assets-bulle-right-svg,url('../../assets/bulle-right.svg'));
      background-position: left bottom;
      background-repeat: no-repeat;
    }
    .background-3 {
      bottom:0;
      right:-16px;
      position:absolute;
      z-index:0;
      width:calc(50% + 18px);
      height:51%;
      background-image: var(--shared-assets-bulle-right-svg,url('../../assets/bulle-right.svg'));
      background-position: right bottom;
      background-repeat: no-repeat;
    }
  }
  &.bulle-left {
    .background-0 {
      position:absolute;
      z-index:0;
      top:0;
      left:-16px;
      width:calc(50% + 18px);
      height:50%;
      background-image: var(--shared-assets-bulle-left-svg,url('../../assets/bulle-left.svg'));
      background-position: left top;
      background-repeat: no-repeat;
    }
    .background-1 {
      top:0;
      right:0;
      position:absolute;
      z-index:0;
      width:50%;
      height:50%;
      background-image: var(--shared-assets-bulle-left-svg,url('../../assets/bulle-left.svg'));
      background-position: right top;
      background-repeat: no-repeat;
    }
    .background-2 {
      bottom:0;
      left:-16px;
      width:calc(50% + 18px);
      position:absolute;
      z-index:0;
      height:51%;
      background-image: var(--shared-assets-bulle-left-svg,url('../../assets/bulle-left.svg'));
      background-position: left bottom;
      background-repeat: no-repeat;
    }
    .background-3 {
      bottom:0;
      right:0;
      position:absolute;
      z-index:0;
      width:50%;
      height:51%;
      background-image: var(--shared-assets-bulle-left-svg,url('../../assets/bulle-left.svg'));
      background-position: right bottom;
      background-repeat: no-repeat;
    }
  }
}
