.markdown-preview .MuiTypography-h1 {
  font-size:40px;
  margin-top:15px;
  &:first-child {
    margin-top:0;
  }
}
.markdown-preview .MuiTypography-h2 {
  margin-top:15px;
  &:first-child {
    margin-top:0;
  }
  font-size:30px;
}
.markdown-preview .MuiTypography-h3 {
  margin-top:15px;
  &:first-child {
    margin-top:0;
  }
  font-size:24px;
}
.markdown-preview .MuiTableContainer-root {
  margin-bottom:15px;
}
.mkdwn-credits {
  .MuiTypography-h1 {
    font-weight:100;
    margin:0;
    line-height:1;
  }
  .MuiTypography-body1 {
    margin-top:15px;
    margin-bottom:0;
    strong {
      font-weight:900;
    }
  }
}
.markdown-preview {
  .MuiTypography-body1, li  {
    font-size:1.2em;
  }
  img[alt='icon'] {
    width:50px;
    margin:5px;
    vertical-align: middle;
  }
  img[alt='image'] {
    max-width: calc(100% - 10px);
    margin:5px;
    vertical-align: middle;
  }
}
