.modal-mask {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:#000A;
  display:none;
  z-index:1001;
  &.on {
    display:block;
  }
  & .modal-inner {
    position: absolute;
    width:calc(var(--width) -  60px);
    height:calc(var(--reverseWindowHeight) - 110px);
    left:calc(var(--left) + var(--ratio) * 30px);
    top:calc(var(--ratio) * 30px);
    transform:scale(var(--ratio));
    transform-origin: top left;
    border-radius: 16px;
    overflow:hidden;
    h3 {
      font-size:1.7em;
    }
    .markdown-preview {
      font-size:20px;
      line-height: 1.2;
      .MuiTypography-root {
        font-size:20px;
        line-height: 1.2;
      }
    }
  }
}
