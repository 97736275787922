.helper {
  pointer-events: none;
  position:fixed;
  z-index:1000;
  top:0;
  left:0;
  width:100%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &.touch {
    align-items: flex-end;
  }
  &.zoom-plus {
    z-index:1001;
  }
  .touch-helper {
    position:relative;
    width:100px;
    height:100px;
    opacity:0.7;
    margin-bottom:30px;
  }
  .zoom-helper {
    position:relative;
    width:400px;
    height:400px;
    opacity:0.7;
  }
  &.landscape {
    //display:none;
  }
}
