.loader {
  font-size: 200px;
  color:#FFF;
  background-color: #000;
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  opacity:1;
  transition:opacity 1s;
  z-index:998;
  .click-needed {
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:30px;
  }
  .animation-wrapper {
    position:absolute;
    height:var(--screenHeight);
    width:calc( 1.78 * var(--screenHeight));
    top:0;
    left:calc(50vw - 0.89 * var(--screenHeight));
    .loader-animation {
      position:absolute;
      width:100%;
      height:100%;
      top:0;
      left:0;
      .skip {
        position:fixed;
        bottom:30px;
        right:30px;
        width:50px;
        height:50px;
        transform: scale(var(--ratio));
        transform-origin: right bottom;
        opacity:0.8;
        transition:opacity 0.2s;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display:flex;
        justify-content: center;
        align-items: center;
        color:#FFF;
        cursor:pointer;
        background-image: var(--shared-assets-skip-svg,url('../../shared/assets/skip.svg'));
        pointer-events: all;
        &:hover {
          opacity:1;
        }
      }
    }
  }
  .loader-splashScreen {
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:30px;
    img {
      position:absolute;
      width:100%;
      height:100%;
      top:0;
      left:0;
      object-fit: contain;
      object-position: center;
    }
    .opener {
      position:absolute;
      width:100%;
      height:100%;
      top:0;
      left:0;
      background-color: #000;
      opacity:1;
      transition: opacity 1s;
      &.hide {
        opacity:0;
      }
      video {
        position:absolute;
        width:100%;
        height:100%;
        top:0;
        left:0;
        object-fit: contain;
        object-position: center;
        background-color: #000;
      }

    }
  }
  .loader-progress {
    position:fixed;
    z-index:1000;
    width:var(--width);
    height:var(--reverseWindowHeight);
    left:var(--left);
    top:0;
    transform-origin: top left;
    transform: scale(var(--ratio));
    pointer-events: none;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    pointer-events: none;
    .chargement {
      position:absolute;
      bottom:160px;
      font-size:16px;
      font-weight:100;
    }
    .loader-bar-container {
      position:absolute;
      bottom:150px;
      opacity:1;
      transition:opacity 0.5s;
      width:70%;
      background-color:#FFF2;
      .loader-bar {
        background-color:#FFF;
        height:2px;
      }
    }
    .need-action {
      font-size:16px;
    }
  }
  &.loaded {
    .loader-progress {
      .loader-bar-container, .chargement{
        opacity:0;
      }
    }
  }
  &.done {
    opacity:0;
    pointer-events: none;
  }
}
